// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";

import "admin-lte";
import $ from "jquery";
global.$ = $;
global.jQuery = $;

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');

import "@fortawesome/fontawesome-free/js/all";

import toastr from "toastr";

import dayjs from "dayjs";

import "select2";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";

import Swal from "sweetalert2";

global.Swal = Swal;

Rails.start();

if (window.location.hash.slice(0, 7) == "#token=") {
  window.location.href =
    window.location.pathname +
    (window.location.search.length ? "&" : "?") +
    "&token=" +
    window.location.hash.slice(7);
}

$(document).ready(function() {
  if (typeof messages != "undefined") {
    Object.keys(messages).forEach(function(key) {
      if (key == "alert") {
        toastr.error(messages[key]);
      } else if (key == "notice") {
        toastr.success(messages[key]);
      } else {
        toastr.info(messages[key]);
      }
    });
  }

  $('#project-report-links').sortable({
    handle: '.input-group-prepend'
  });
});

$(document).on("change", "#sprint_start", function() {
  $("#sprint_end").val(
    dayjs($(this).val())
      .add(config.DEFAULT_SPRINT_DUR, "seconds")
      .subtract(1, "day")
      .format("YYYY-MM-DD")
  );
});

document.addEventListener("DOMContentLoaded", function() {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    document.body.classList.add("dark-mode");
    document.getElementById("nav").classList.add("navbar-dark");
    document.getElementById("nav").classList.remove("navbar-light");
  }

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", (e) => {
      // console.log(e.matches);
      if (e.matches) {
        document.body.classList.add("dark-mode");
        document.getElementById("nav").classList.add("navbar-dark");
        document.getElementById("nav").classList.remove("navbar-light");
      } else {
        document.body.classList.remove("dark-mode");
        document.getElementById("nav").classList.remove("navbar-dark");
        document.getElementById("nav").classList.add("navbar-light");
      }
    });
});

window.addEventListener(
  "online",
  function(e) {
    document.body.classList.remove("offline");
    window.location.reload();
  },
  false
);

window.addEventListener(
  "offline",
  function(e) {
    document.body.classList.add("offline");
  },
  false
);

$(document).ready(function() {
  $(".select2").select2({
    width: "100%",
    theme: "bootstrap4",
    allowClear: true,
    placeholder: $(this).data("placeholder"),
  });
});

$(document).on("click", ".edit-payment", function() {
  var data = $(this).data("payment");
  // console.log(data);

  $("#paymentModal h5").html(data.project.title);

  $("#paymentModal form").attr("action", `/payments/${data.id}`);

  $("#paymentModal form #monthly_payment_id").val(data.id);

  $("#paymentModal form #monthly_payment_amount").val(data.amount);
  $("#paymentModal form #monthly_payment_report_link").val(data.report_link);
  $("#paymentModal form #monthly_payment_manager_id")
    .val(data.manager_id)
    .trigger("change");
  $("#paymentModal form #monthly_payment_comment").val(data.comment);
  $("#paymentModal form #monthly_payment_kind").val(data.kind);
  $("#paymentModal form #monthly_payment_status").val(data.status);

  $("#paymentModal").modal("show");
});

$(document).on("click", ".copy-edit-link-payment", function() {
  navigator.clipboard.writeText($(this).data("url")).then(
    function() {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Success",
        text: "Copied!",
      });
    },
    function() {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        title: "Failed",
      });
    }
  );
});

$(document).on("click", ".hidden-sum-switcher", function(e) {
  e.preventDefault();

  $(this)
    .siblings(".hidden-sum")
    .toggle();
  $(this).toggleClass("active");
});

$(document).on("change", "#current_month", function(e) {
  e.preventDefault();

  window.location.pathname = $(this).val();
});

$(document).on("change", 'select[form="filter"]', function(e) {
  e.preventDefault();

  $("form#filter").submit();
});

$(document).on("click", ".sortable-column", function(e) {
  e.preventDefault();

  $("form#filter #order_column").val($(this).data("column"));
  $("form#filter #order_direction").val($(this).data("direction"));

  $("form#filter").submit();
});

$(document).on("click", ".print-envelopes", function(e) {
  let ids = $.map($(".user-envelope:checked"), (item) => $(item).val());
  if (ids.length) {
    $("#envelopes_ids").val(ids);
    $("#modal-envelope-mask").modal();
  }
});

$(document).on("click", ".remove-report_link", function(e) {
  e.preventDefault();

  $(this)
    .parents(".input-group")
    .remove();
});

$(document).on("click", ".add-report_link", function(e) {
  e.preventDefault();

  $(this).before(
    '<div class="input-group" style="margin-bottom: 5px;"><input type="text" name="project[report_links][]" class="form-control"><span class="input-group-append"><button class="btn btn-danger btn-flat remove-report_link"><i class="fa fa-minus"></button></span></div>'
  );
});

$(document).on("change", "#set_managers_mode select", function() {
  // console.log(this.parentNode);
  Rails.fire(this.parentNode, "submit");
});
